<div
  class="container content-space-2 content-space-t-xl-2 content-space-b-lg-2"
>
  <!-- Heading -->
  <div class="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
    <h2 class="h1">Most Popular Data and APIs</h2>
    <p>
      Explore our most sought-after datasets and APIs, acclaimed for quality and
      innovation by industry leaders.
    </p>
  </div>
  <!-- End Heading -->

  <!-- Loading Skeleton -->
  <div class="row mb-5 mb-md-0" *ngIf="!apiList">
    <div class="col-sm-6 col-lg-3 mb-4" *ngFor="let i of [1, 2, 3, 4]">
      <div class="card card-sm h-100">
        <div class="p-2">
          <div class="placeholder-glow">
            <div class="placeholder w-100" style="height: 140px"></div>
          </div>
        </div>
        <div class="card-body">
          <div class="placeholder-glow">
            <div class="placeholder w-75 mb-3"></div>
            <div class="placeholder w-100 mb-2"></div>
            <div class="placeholder w-100 mb-2"></div>
            <div class="placeholder w-50"></div>
          </div>
        </div>
        <div class="card-footer border-top">
          <div class="placeholder-glow">
            <div class="placeholder w-25"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Loading Skeleton -->

  <!-- Content -->
  <div class="row mb-5 mb-md-0" *ngIf="apiList">
    <div
      class="col-sm-6 col-lg-3 mb-4"
      *ngFor="let item of apiList | slice : 0 : 4"
    >
      <!-- Card -->
      <div class="card card-sm h-100">
        <div class="p-2">
          <a routerLink="/details/{{ item.apiIdentityName }}">
            <img
              class="card-img"
              [src]="item.apiCoverImage | cfcdn : 'middle'"
              alt="Image Description"
          /></a>
        </div>

        <div class="card-body">
          <h4 class="card-title">
            <a routerLink="/details/{{ item.apiIdentityName }}">{{
              item.apiName
            }}</a>
          </h4>
          <p class="card-text">
            {{ item.apiDescription }}
          </p>

          <!-- List Pointer -->
          <ul class="list-pointer mb-0" *ngFor="let item of item.apiTagses">
            <li class="list-pointer-item">{{ item.apiTagName }}</li>
          </ul>
          <!-- End List Pointer -->
        </div>

        <a
          class="card-footer card-link border-top"
          routerLink="/details/{{ item.apiIdentityName }}"
          >View <i class="bi-chevron-right small ms-1"></i
        ></a>
      </div>
      <!-- End Card -->
    </div>
  </div>
  <!-- End Content -->
</div>
