import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { APIService } from '@app/core/services/api.service';
import { ApiContent, ApiResponse } from '@app/models/interface';
import { CFCDNPipe } from '@app/pipeline/cf-cdn.pipe';

@Component({
  selector: 'gugudata-category-list',
  standalone: true,
  imports: [RouterLink, CommonModule, ReactiveFormsModule, CFCDNPipe],
  templateUrl: './category-list.component.html',
  styleUrl: './category-list.component.scss',
})
export class CategoryListComponent {
  public apiList: ApiContent[] = [];

  constructor(private apiService: APIService) {
    this.apiService
      .getAPIs('', '', '', false, 'RELEASE_TIME_DESC', 1, 4)
      .subscribe((res: ApiResponse) => {
        this.apiList = res.content;
      });
  }
}
